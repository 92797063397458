@charset "UTF-8";
@import 'mixins';

@include font-face("Montserrat", "../fonts/MontserratBold/MontserratBold", 700); 
@include font-face("Montserrat", "../fonts/MontserratRegular/MontserratRegular", normal); 

/*** MIXINS USAGE ***

	@include xs(12);
	@include sm(12);
	@include md(12);
	@include lg(12);

	@include r(768);
	@include rmin(768);

	@include trans / trans($what $dur $easing);
	@include cover;

	@include centerX / centerXa
	@include centerY / centerYa

	@include overlay($opacity)

***/

.container-fluid {
	max-width: 1400px;
}

.icon {
	display: inline-block;
	width: 2em;
	height: 2em;
	font-size: 30px;
}

html, body {
	height: 100%;
}

body {
	font-family: 'Montserrat', sans-serif;
	font-size: 14px;
	color: #37474F;
}

.header {
    padding: 30px 65px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 113px;
    z-index: 10;
    mix-blend-mode: exclusion;
    filter: invert(65%);
    &__logo {
    	width: 50px;
    	img {
    		width: 100%;
    	}
    }
    &__menu {
    	position: absolute;
    	top: 30px;
    	right: 65px;
		width: 50px;
		cursor: pointer;
    }
}

.text__first, .text__second{
  position: relative;
}

.text__word{
  opacity: 0;
}

.text__first-bg, .text__second-bg{
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 100;
  transform-origin: left;
  transform: scaleX(0);
}

.text__first-bg{
  background-color: #5fbff9;
}

.text__second-bg{
  background-color: #f06543;
}

.textCenter {
	padding: 80px 0;
	p {
		font-size: 36px;
		text-align: center;
	}
}

.cards {
	padding: 60px 0;
}

.card {
	height: 468px;
	width: 100%;
	@include cover;
	float: left;
	&:nth-child(10n+1) {width: 66.67%;}
	&:nth-child(10n+2) {width: 33.33%;}
	&:nth-child(10n+3) {width: 33.33%;}	
	&:nth-child(10n+4) {width: 66.67%;}	
	&:nth-child(10n+5) {width: 33.33%;}	
	&:nth-child(10n+6) {width: 33.33%;}	
	&:nth-child(10n+7) {width: 33.33%;}	
	&:nth-child(10n+8) {		
		width: 66.67%;
		height: 936px;
	}	
	&:nth-child(10n+9) {width: 33.33%;}	
	&:nth-child(10n+10) {width: 33.33%;}	
}

.form {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 99999;
	padding: 20px;
	background: #f9f9f9;
	opacity: 0.8;
	div {
		margin-bottom: 10px;
	}
	label {
		display: block;
		font-size: 13px;
	}
	input {
		padding-left: 10px;
		display: block;
		font-size: 13px;
		width: 250px;
	}
	span {
		font-size: 11px;
	}
}